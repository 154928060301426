@import "../node_modules/react-rrule-generator/build//styles.css";
@import "../node_modules/react-grid-layout/css/styles.css";
@import "../node_modules/react-resizable/css/styles.css";
@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300,400,500,600,700&display=swap");

@font-face {
  font-family: "Lato";
  font-weight: 200;
}

@font-face {
  font-family: "Lato";
  font-weight: 300;
}

@font-face {
  font-family: "Lato";
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  font-weight: 500;
}

@font-face {
  font-family: "Lato";
  font-weight: 700;
}


html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Lato", "Helvetica", "Arial", sans-serif;
}

body {
  background-color: rgb(244, 244, 244);
  color: #808080;
  margin: 0;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  font-size: 14px;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

strong {
  font-weight: 500;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.6em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
  margin-top: 10px;
  margin-bottom: 10px;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 500;
}

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

p {
  margin: 0 0 10px;
  letter-spacing: 0.7px;
}

blockquote p {
  font-style: italic;
}

a {
  color: #0091eb;
  text-decoration: none;
}

a:not([role="button"]):hover,
a:not([role="button"]):focus {
  color: #007bc8;
  text-decoration: none;
}

legend {
  border-bottom: 0;
  margin-bottom: 20px;
  font-size: 21px;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

@media (max-width: 991px) {

  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}

svg {
  touch-action: none;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: 400;
  line-height: 1;
  color: #777;
}

h4 small,
h5 small,
h6 small {
  font-size: 75%;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

img {
  vertical-align: middle;
  border: 0;
}

.text--center {
  text-align: center;
}

button:focus {
  outline: none;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}


.StripeElement {
  background-color: #eeeeee;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

// Grid table
.super-row-status--new {
  background-color: #cfd8dc;
}

// Mentions library highlight styles
.mentions__mention {
  position: relative;
  z-index: 1;
  color: blue;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}

// Override the input of react-mentions component styles
.mentions__input__input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  margin: 0;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: MuiInputBase-keyframes-auto-fill-cancel;
  -webkit-tap-highlight-color: transparent;
  height: auto;
  resize: none;
  padding: 0;
}

.mentions__input {
  min-height: 80px;
  width: 100%;
}

// Tags list styles
// remove bullet in maerial ui lists
li {
  list-style-type: none !important;
}

.tags-status,
.tags-status ul,
.tags-status li {
  position: relative;
}

.tags-status ul {
  list-style: none;
  padding-left: 32px;
}

.tags-status li::before,
.tags-status li::after {
  content: "";
  position: absolute;
  left: -12px;
}

.tags-status li::before {
  border-top: 1px solid #000;
  top: 13px;
  width: 8px;
  height: 0;
}

.tags-status li::after {
  border-left: 1px solid #000;
  height: 100%;
  width: 0px;
  top: 6px;
}

.tags-status ul>li:last-child::after {
  height: 8px;
}

.tags-status-root,
.tags-status-root ul,
.tags-status-root li {
  position: relative;
}

.tags-status-root ul {
  list-style: none;
  padding-left: 32px;
}

.tags-status-root li::before,
.tags-status-root li::after {
  content: "";
  position: absolute;
  left: -12px;
}

.tags-status-root li::before {
  // border-top: 1px solid #000;
  top: 13px;
  width: 8px;
  height: 0;
}

.tags-status-root li::after {
  // border-left: 1px solid #000;
  height: 100%;
  width: 0px;
  top: 6px;
}

.tags-status-root ul>li:last-child::after {
  height: 8px;
}

// BIG CALENDAR STYLES
.greyFont {
  color: rgb(133, 133, 133)
}

.whiteFont {
  color: #fff;
}

.rbc-allday-cell .rbc-row-content {
  overflow-y: scroll;
  /* using auto here causes misaligned days on week view. Might work on day view */
  max-height: 120px;
  /* amend height to suit your use case */
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #FF6600;
  pointer-events: none;
}

.rbc-month-view,
.rbc-time-view,
.rbc-agenda-view {
  background-color: #fff;
}

.rbc-header {
  background-color: #b6bec4;
  color: #2F4858;
  font-size: 18px;
  height: 30px;
  margin-bottom: 8px;
  text-align: center;
  font-weight: 600;
  border-right: 1px solid #CECECE;
}

.rbc-header:last-of-type {
  border-top-right-radius: 12px;
}

.rbc-month-view,
.rbc-time-view,
.rbc-agenda-view {
  position: relative;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}

// RRULE GENERATOR BOOTSTRAP OVERRIDES
.btn-primary {
  background-color: #b0bec5;
  border-color: #cfd8dc;
}

.btn-primary:hover {
  background-color: #90a4ae;
  border-color: #b0bec5;
  cursor: pointer;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: #2F4858;
  border-color: #78909c;
}

// React Dragable Grid
.grid-item__card:hover {
  cursor: move;
  height: 100%;
  // overflow: auto;
  border: 2px dashed #858585 !important;
}

.grid-item__card_edit_mode:hover {
  cursor: move;
  height: 100%;
  // overflow: auto;
  border: 2px dashed #858585 !important;
}

.grid-item__card:hover .board-card-edit-fab {
  display: flex;
}

.grid-item__card_edit_mode .board-card-edit-content {
  opacity: 50%;
}

.grid-item__card_edit_mode .board-card-edit-fab {
  display: flex;
}

.react-grid-item>.react-resizable-handle::after {
  width: 0;
  height: 0;
  border-right: 0;
  border-left: 0;
}

.react-resizable-handle {
  height: 50px !important;
  width: 50px !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='svg-icon' style='width: 12px; height: 12px;vertical-align: middle;fill: currentColor;overflow: hidden;' viewBox='0 0 1024 1024' version='1.1'%3E%3Cpath d='M768 256l64 0 0 64-64 0zM640 384l64 0 0 64-64 0zM640 512l64 0 0 64-64 0zM640 640l64 0 0 64-64 0zM512 512l64 0 0 64-64 0zM512 640l64 0 0 64-64 0zM384 640l64 0 0 64-64 0zM768 384l64 0 0 64-64 0zM768 512l64 0 0 64-64 0zM768 640l64 0 0 64-64 0zM768 768l64 0 0 64-64 0zM640 768l64 0 0 64-64 0zM512 768l64 0 0 64-64 0zM384 768l64 0 0 64-64 0zM256 768l64 0 0 64-64 0z'/%3E%3C/svg%3E")
}

.suggestion-item {
  -webkit-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
  background-color: #ffffff !important;
}

@keyframes flickerAnimation {

  /* flame pulses */
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}