@mixin workspace-header-layout {
  height: var(--header-height);
  min-height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0 20px;

  .workspace-header__block,
  .workspace-header__block-item {
    display: flex;
    align-items: center;
    gap: 10px;
    overflow-y: hidden;
    min-width: fit-content;
  }

  > .workspace-header__block:first-of-type {
    min-width: 0;
    overflow-x: hidden;
  }

  .workspace-header__title,
  .workspace-header__subtitle {
    white-space: nowrap;
  }

  // button {
  //   @include reset-button-style;
  //   @include center-content;
  //   gap: 4px;
  // }
}