html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.app__wrapper {
  border-radius: 16px;
  display: flex;
  height: 85vh;
  // width: 100vw;
  max-width: 100vw;
  box-shadow: rgba(0, 0, 0, 0.33) 0 1px 4px 0;

  @media only screen and (max-width: 1000px) {
    .sidebar {
      width: 60px;
    }
  }

  @media only screen and (max-width: 768px) {
    .str-chat__thread,
    .pinned-messages__container {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1000;
      width: 100vw;
      max-width: 100%;
      background: white;
    }
  }
}

.sidebar {
  width: 300px;
}

.channel__container {
  height: 100%;
  width: 100%;
  min-width: 0;
  flex: 1;
}

.str-chat__thread,
.pinned-messages__container {
  width: 30%;
  min-width: 300px;
}

.chat__load-more-button__button {
  padding: 4px !important;
}