@mixin reset-button-style {
  border: none;
  background: none;
}

@mixin center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin ellipsis-text {
  white-space: nowrap;
  overflow-y: visible;
  // Not every browser support clip overflow, so hidden is defined as a fallback
  overflow-x: hidden;
  overflow-x: clip;
  text-overflow: ellipsis;
}